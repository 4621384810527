@import "../../styles/helpers";



.header {
    position: relative;
    z-index: 10;
    padding: 48px 0;
    @include m {
        padding-bottom: 24px; } }

.container {
    display: flex;
    align-items: center;
    & > .button {
        @include m {
            display: none; } } }

.logo {
    margin-right: 48px;
    @include d {
        margin-right: auto; } }

.wrap {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-left: 48px;
    border-left: 1px solid $neutrals6;
    @include x {
        padding-left: 0;
        border: none; }
    @include d {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        align-items: flex-start;
        padding: 0 80px 80px;
        background: $neutrals8;
        box-shadow: 0px 64px 64px 0 rgba(15, 15, 15, 0.1);
        &.active {
            display: flex; } }
    @include t {
        padding: 0 40px 64px; }
    @include m {
        flex-direction: column;
        align-items: flex-start;
        min-height: calc(100vh - 104px);
        padding: 48px 32px 44px; }
    @include dark {
        border-color: $neutrals3;
        @include d {
            background: $neutrals1; } }
    .button {
        display: none;
        @include m {
            display: inline-flex;
            margin-top: 48px; } } }

.nav {
    display: flex;
    align-items: center;
    margin-right: 20px;
    @include d {
        flex-direction: column;
        align-items: flex-start;
        flex: 0 0 calc(100% - 416px);
        max-width: calc(100% - 416px);
        margin-right: 0; }
    @include t {
        flex: 0 0 calc(100% - 344px);
        max-width: calc(100% - 344px); }
    @include m {
        align-items: stretch;
        flex: auto 0 calc(100% + 64px);
        width: calc(100% + 64px);
        max-width: calc(100% + 64px);
        margin: 0 -32px auto; } }

.link,
.group,
.nav > div {
    &:not(:last-child) {
        margin-right: 32px;
        @include x {
            margin-right: 24px; }
        @include d {
            margin: 0 0 48px; }
        @include m {
            margin: 0; } } }

.link {
    display: block;
    padding: 16px 8px;
    @include button-2;
    color: $neutrals4;
    transition: color .2s;
    @include d {
        padding: 0;
        font-size: 40px;
        line-height: 1.2;
        letter-spacing: -.01em; }
    @include t {
        font-size: 32px; }
    @include m {
        padding: 18px 32px;
        font-size: 24px; }
    &:hover {
        color: $neutrals2;
        @include dark {
            color: $neutrals8; } }
    &.active {
        color: $neutrals2;
        @include m {
            box-shadow: inset 2px 0 0 0 $red; }
        @include dark {
            color: $neutrals8; } } }

.details {
    display: flex;
    align-items: center;
    flex-grow: 1;
    @include d {
        display: block;
        flex: 0 0 416px; }
    @include t {
        flex: 0 0 344px; }
    @include m {
        flex: 0 0 auto; } }


.contact {
    display: none;
    @include d {
        display: block;
        margin-bottom: 32px; }
    @include m {
        display: none; } }

.element {
    &:not(:last-child) {
        margin-bottom: 32px;
        @include t {
            margin-bottom: 24px; } } }

.category {
    margin-bottom: 8px;
    @include body-1;
    @include t {
        margin-bottom: 4px;
        font-size: 18px; } }

.text {
    @include caption-1;
    color: $neutrals4; }

.socials {
    display: flex;
    margin: 0 auto;
    @include m {
        display: none; } }

.social {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    svg {
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover svg {
        fill: $neutrals2;
        @include dark {
            fill: $neutrals8; } }
    &:not(:last-child) {
        margin-right: 24px; } }

.burger {
    display: none;
    @include d {
        display: block;
        position: relative;
        margin-left: 40px;
        width: 32px;
        height: 32px;
        background: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        &.active {
            &:before {
                transform: translateY(0) rotate(-45deg); }
            &:after {
                transform: translateY(0) rotate(45deg); } }
        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 16px;
            left: 6px;
            width: 20px;
            height: 2px;
            background: $neutrals4;
            border-radius: 2px;
            transition: transform .2s; }
        &:before {
            transform: translateY(-4px); }
        &:after {
            transform: translateY(3px); } }
    @include t {
        margin-left: 32px; } }
